import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/home/:path',
    name: 'layout',
    component: () => import('@/layout'),
    meta: { title: '个人中心' },
    children: [
      {
        path: '/home/:path',
        component: () => import('@/views/home/index.vue'),
        children: [],
        meta: { title: '个人中心' }
      },
      {
        path: '/:path',
        component: () => import('@/views/clinic/index.vue'),
        children: [],
        meta: { title: '首页' }
      },
      {
        path: '/appointment/my/:path',
        component: () => import('@/views/appointment/index'),
        meta: { title: '预约中心', menu: 7, keepAlive: true }
      }
    ]
  },
  {
    path: '/appointment/info/:path/:clinicId/:appointId',
    component: () => import('@/views/appointment/appoint-info'),
    meta: { title: '预约申请详情' }
  },
  {
    path: '/booking/info/:path/:clinicId/:appointId',
    component: () => import('@/views/appointment/booking-info'),
    meta: { title: '预约详情' }
  },

  {
    path: '/appointment/:path',
    component: () => import('@/views/appointment/index'),
    meta: { title: '预约中心', menu: 7 }
  },
  {
    path: '/my/:path',
    component: () => import('@/views/my'),
    meta: { title: '我的' }
  },
  {
    path: '/my/bind/:path',
    component: () => import('@/views/my/bind-mobile.vue'),
    meta: { title: '绑定手机' }
  },
  {
    path: '/membership/:path',
    component: () => import('@/views/membership-card'),
    meta: { title: '我的会员卡', menu: 3 }
  },
  {
    path: '/appointment/add/:path',
    component: () => import('@/views/appointment-add'),
    meta: { title: '在线预约', menu: 8 }
  },
  {
    path: '/receipt/:path',
    component: () => import('@/views/receipt'),
    meta: { title: '消费', menu: 4 }
  },
  {
    path: '/receipt/info/:path/:receiptId',
    component: () => import('@/views/receipt-info'),
    meta: { title: '消费详情' }
  },
  {
    path: '/emr/:path',
    component: () => import('@/views/emr'),
    meta: { title: '病历', menu: 7 }
  },
  {
    path: '/emr/info/:path/:emrId',
    component: () => import('@/views/emr-info'),
    meta: { title: '病历详情' }
  },
  {
    path: '/image',
    component: () => import('@/views/image'),
    meta: { title: '影像资料' }
  },
  {
    path: '/sys/message/:path',
    component: () => import('@/views/sys-msg'),
    meta: { title: '系统消息' }
  },
  {
    path: '/sys/info/:path/:msgId',
    component: () => import('@/views/sys-msg/info'),
    meta: { title: '系统消息详情' }
  },
  {
    path: '/clinicMsg/:path',
    component: () => import('@/views/clinic-message/index.vue'),
    meta: { title: '关于诊所' }
  },
  {
    path: '/docGroup/:path',
    component: () => import('@/views/doc-group/index.vue'),
    meta: { title: '医生团队' }
  },
  {
    path: '/doc/info/:path/:clinicId/:docId',
    component: () => import('@/views/doc-info/index.vue'),
    meta: { title: '医生详情' }
  },
  {
    path: '/contact/:path',
    component: () => import('@/views/contact/index.vue'),
    meta: { title: '联系诊所' }
  },
  {
    path: '/question/:path/:clinicId/:registrationId',
    component: () => import('@/views/question/index.vue'),
    meta: { title: '问卷调查' }
  },
  {
    path: '/question/result',
    component: () => import('@/views/question-result/index.vue'),
    meta: { title: '问卷结果' }
  },
  {
    path: '/scoreredirect/:path',
    component: () => import('@/views/scoreredirect/index.vue'),
    meta: { title: '' }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
