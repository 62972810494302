/**
 * 网页授权流程
 * @param {*} pageUrl 回调页面
 */
const useWxAuth = async ({
  router,
  store,
  route,
  isForce = false,
  page = ''
}) => {
  await router.isReady()
  const searchParams = new URLSearchParams(window.location.search)
  const code = searchParams.get('code')

  const { path } = route.params

  // 如果在
  const pageUrl = page || route.fullPath

  console.log(route.params)
  console.log(pageUrl)
  console.log(path)

  // if ((code || pageUrl.includes('my/bind')) && !isForce) {
  if (code && !isForce) {
    return
  }

  /** 获取公众号信息 */
  const getAccountData = async () => {
    await store.dispatch('app/getAccountData', path)
  }

  const url = encodeURIComponent(`https://patient.igensoft.com.cn/#${pageUrl}`)

  const handlePushWxAuth = async () => {
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${store.getters.accountData.appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
  }

  if (pageUrl.includes('image')) {
    return
  }

  await getAccountData()
  await handlePushWxAuth()
}

export default useWxAuth
